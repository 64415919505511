enum MaterialCategories {
  Metal,
  Plastic,
  Lockers,
  Dryers,
  Phenolic,
  WashroomAccessories,
}

export default MaterialCategories;
