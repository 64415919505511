import rdUnitType from '@/enums/rdUnitType';
import { HardwarePackageRequest } from '@/models/hardware/hardwarePackage';
import RoomParts from '@/models/room/roomParts';
import Finish from '../finish/finish';
import DoorHinge from '../hardware/doorHinge';
import HardwareMaterial from '../hardware/hardwareMaterial';
import Latch from '../hardware/latch';
import NoSightLine from '../hardware/noSightLine';
import Fastener from '../lockers/fastener';
import HookType from '../lockers/hookType';
import LatchOption from '../lockers/latchOption';
import Lock from '../lockers/lock';
import LockPreparationType from '../lockers/lockPreparationType';
import LockersSeries from '../lockers/lockersSeries';
import PackagingOption from '../lockers/packagingOption';
import PlateMaterial from '../lockers/plateMaterial';
import Tier from '../lockers/tier';
import Type from '../lockers/type';
import Material from '../material/material';
import Option from '../option';
import PaginatedResponse from '../paginatedResponse';
import AffOptions from '../partition/affOptions';
import Partition from '../partition/partition';
import PatchOperation from '../patchOperation/patchOperation';
import Organization from '../permission/organization';
import RdDrawingNote from '../roomDesigner/rdDrawingNote';
import Series from '../series/series';
import Project from './project';

export const adaCompliantLockerTiers = [3, 4, 5, 6];

export enum CreationTypes {
  Undecided = 'Undecided',
  Partitions = 'Partitions',
  Lockers = 'Lockers',
  WashroomAccessories = 'WashroomAccessories',
}

export enum ProjectProductTypes {
  Partition,
  Lockers,
  WashroomAccessories,
}

export class ProjectsResponse extends PaginatedResponse {
  readonly entities: Project[];
}

export class RoomBomRequest {
  projectId: string;
  roomId: string;
}

export class DeleteProjectPartRequest {
  partId: string;
  projectId: string;
}

export class ProjectCreationRequest {
  projectName: string;
  clientName: string;
  distributorId: string;
  productType: ProjectProductTypes;
  projectSettingsRequest?: PartitionProjectSettingsRequest;
  lockersStylesCreationRequest?: LockersStyleCreationRequest;
}

export interface SaveMarkupRequest {
  projectId: string;
  markup: number;
}

export class LockersStyleCreationRequest {
  id?: number;
  projectId?: string;
  name: string;
  series: number;
  type: number;
  solidSidesEnabled: boolean;
  doorFinish?: string;
  frameFinish?: string;
  bodyFinish?: string;
  bootRacksFrameFinish?: string;
  bootRacksShelvesFinish?: string;
  packagingOption?: number;
  width: number | null;
  depth: number | null;
  height: number | null;
  fastener?: number;
  tier?: number;
  lockPreparation?: number;
  latchOptions?: number;
  lock?: number;
  adaLock?: number;
  hookType?: number;
  plateMaterial?: number;
  hasPunchedOutDoors: boolean;
  bootRacksWidth: number | null;
  bootRacksShelvesQuantity: number | null;
}

export class PartitionProjectSettingsRequest {
  finishId: string;
  finishTypeId: string;
  partitionId: string;
  hardwarePackage: HardwarePackageRequest;
  materialId: string;
  seriesId: string;
  affOptionsId: string;
  affOptionRespectingAdaStandardsEnabledForAdaAndAmbUnits: boolean;
  ceilingHeight?: number;
  doorHeight?: number;
}

export class ProjectCreationViewModel {
  projectName?: string;
  clientName = '';
  distributor?: Organization;
  creationType: CreationTypes;
}

export class PartitionSettingsViewModel {
  finish?: Finish;
  finishTypeId?: string;
  series?: Series;
  partitionType?: Partition;
  hardwarePackage: HardwarePackageCreationViewModel;
  material?: Material;
  affOption?: AffOptions;
  affOptionRespectingAdaStandardsEnabledForAdaAndAmbUnits: boolean;
  ceilingHeight?: number;
  doorHeight?: number;
  isCeilingHeightUnknown: boolean;
}

export class StyleViewModel {
  id?: number;
  name: string | null;
  series: LockersSeries | null;
  type: Type | null;
  solidSidesEnabled: boolean;
  doorFinish: Finish | null;
  frameFinish: Finish | null;
  bodyFinish: Finish | null;
  bootRacksFrameFinish: Finish | null;
  bootRacksShelvesFinish: Finish | null;
  packagingOption: PackagingOption | null;
  width: number | null;
  depth: number | null;
  height: number | null;
  fastener: Fastener | null;
  tier: Tier | null;
  lockPreparation: LockPreparationType | null;
  latchOptions: LatchOption[];
  lock: Lock | null;
  adaLock: Lock | null;
  hookType: HookType | null;
  plateMaterial: PlateMaterial | null;
  locksProvidedByHadrian: boolean;
  adaLocksProvidedByHadrian: boolean;
  isPartsOnly: boolean;
  hasPunchedOutDoors: boolean;
  bootRacksWidth: number | null;
  bootRacksShelvesQuantity: number | null;
}

export class HardwarePackageCreationViewModel {
  doorHinge?: DoorHinge = undefined;
  hardwareMaterial?: HardwareMaterial = undefined;
  noSightLines: NoSightLine[] = [];
  options: Option[] = [];
  latch?: Latch = undefined;
}

export class ProjectsGetParams {
  DateStart?: string;
  DateEnd?: string;
  SalesAgency?: string;
  Distributor?: string;
  Search?: string;
  PerPage?: number;
  Page?: number;
  Sort?: string;
  SortBy?: string;
  ProductTypes?: number;
  MinPrice?: number;
  MaxPrice?: number;
  PendingShippingQuoteSelected?: boolean;
}

export class ProjectPatchOperation {
  projectId: string;
  operations: PatchOperation[];
  applyToAllRooms?: boolean;
}

export class ProjectDuplicateOperation {
  projectId: string;
  distributorId?: string;
}

export class ProjectBOMParts {
  entity: ProjectRoomParts;
}

export class RoombBOMParts {
  entity: RoomBomReply;
}

export class Unit {
  quantity: number;
  type: rdUnitType;
}

export class ProjectTotals {
  numStandardLockers: number;
  numBootRacks: number;
  numAdaLockers: number;
  numBenches: number;
  numDryers: number;
  numBabyChangingStations: number;
  numDryerAccessories: number;
  numSoapDispensers: number;
}

export class PartsTotal extends ProjectTotals {
  totalNetPrice: number;
  totalWeight: number;
  totalWeightKg: number;
  estimatedSkidWeight: number;
  estimatedSkidWeightKg: number;
  materialWeight: number;
  materialWeightKg: number;
  screensQty: number;
}

export class ProjectRoomParts extends PartsTotal {
  projectId: string;
  partsInBOMList: RoomParts[];
  units: Unit[];
  totalListPrice: number;
  roomsBomReply: RoomBomReply[];
}

export class ProjectCustomLengthPartRemovalRequest {
  projectId: string;
  partId: string;
}

export class RoomBomReply {
  roomId: string;
  roomName: string;
  totalWeight: number;
  totalWeightKg: number;
  totalListPrice: number;
  totalNetPrice: number;
  parts: RoomParts[];
  units: Unit;
  bomIsMissingParts: boolean;
}

export class RoomBomPartsPart {
  id: string;
  quantity: number;
  roomId: string;
  partId: string;
  netPrice: number;
}

export class DuplicateNoteToRoomsRequest {
  projectId: string;
  note: RdDrawingNote;
  selectedRooms: string[];
}

export class ChangePartsFinish {
  projectId: string;
  finishId: string;
  materialId: string;
}
