import HttpService from '@/endpoints/HttpService';
import urlBuilder from '@/helpers/urlBuilder';
import AccessoriesData from '@/models/accessories/accessoriesData';

export enum AccessoriesPath {
  Defaults = '/defaults',
  Static = '/static',
}

const accessoriesUrl = `${process.env.VUE_APP_API_PREFIX}accessories`;

export default class AccesoriesService {
  public static async getStaticAccessoriesData(projectId: string): Promise<AccessoriesData> {
    const url = urlBuilder(this.makeUrl(AccessoriesPath.Static), { projectId });
    const response = await HttpService.get(url);
    return response.data;
  }

  private static makeUrl(path: AccessoriesPath): string {
    return `${accessoriesUrl}${path}`;
  }
}
