import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { AppStateModule } from './appStateModule/appStateModule';
import { DiscountOverridesModule } from './discountOverridesModule/discountOverridesModule';
import { AccessoriesModule } from './accessoriesModule/accessoriesModule';
import { ExpediteRequestsModule } from './expediteRequestsModule';
import { LockersModule } from './lockersModule/lockersModule';
import { OrdersModule } from './ordersModule/ordersModule';
import { OrganizationsModule } from './organizationsModule/organizationsModule';
import { PermissionsModule } from './permissionsModule/permissionsModule';
import { PersistentDataModule } from './persistentDataModule/persistentDataModule';
import { ProjectPartsModule } from './projectPartsModule/projectPartsModule';
import { ProjectSettingsModule } from './projectSettingsModule/projectSettingsModule';
import { ProjectsModule } from './projectsModule/ProjectsModule';
import { ProvincesModule } from './provincesModule/provincesModule';
import { QuotesModule } from './quotesModule/quotesModule';
import { ResourceCenterModule } from './resourceCenterModule/resourceCenterModule';
import { RoomDesignerModule } from './roomDesignerModule/roomDesignerModule';
import { RoomScreenModule } from './roomsModule/roomScreensModule/roomScreensModule';
import { RoomsModule } from './roomsModule/roomsModule';
import { UpdatesModule } from './updatesModule/updatesModule';
import { UserModule } from './userModule/userModule';
import { UserSettingsModule } from './userSettingsModule/userSettingsModule';

export const store = createStore({
  state: { version: '0.1' },
  modules: {
    appStateModule: AppStateModule.ExtractVuexModule(AppStateModule),
    permissionsModule: PermissionsModule.ExtractVuexModule(PermissionsModule),
    userModule: UserModule.ExtractVuexModule(UserModule),
    userSettingsModule: UserSettingsModule.ExtractVuexModule(UserSettingsModule),
    updatesModule: UpdatesModule.ExtractVuexModule(UpdatesModule),
    persistentDataModule: PersistentDataModule.ExtractVuexModule(PersistentDataModule),
    projectSettingsModule: ProjectSettingsModule.ExtractVuexModule(ProjectSettingsModule),
    roomDesignerModule: RoomDesignerModule.ExtractVuexModule(RoomDesignerModule),
    roomsModule: RoomsModule.ExtractVuexModule(RoomsModule),
    provincesModule: ProvincesModule.ExtractVuexModule(ProvincesModule),
    roomScreenModule: RoomScreenModule.ExtractVuexModule(RoomScreenModule),
    projectPartsModule: ProjectPartsModule.ExtractVuexModule(ProjectPartsModule),
    lockersModule: LockersModule.ExtractVuexModule(LockersModule),
    expediteRequestsModule: ExpediteRequestsModule.ExtractVuexModule(ExpediteRequestsModule),
  },
  plugins: [createPersistedState({ storage: sessionStorage })],
});

const nonPersistedStore = createStore({
  state: { version: '0.1' },
  modules: {
    discountOverridesModule: DiscountOverridesModule.ExtractVuexModule(DiscountOverridesModule),
    accessoriesModule: AccessoriesModule.ExtractVuexModule(AccessoriesModule),
    orderModule: OrdersModule.ExtractVuexModule(OrdersModule),
    organizationsModule: OrganizationsModule.ExtractVuexModule(OrganizationsModule),
    projectsModule: ProjectsModule.ExtractVuexModule(ProjectsModule),
    quotesModule: QuotesModule.ExtractVuexModule(QuotesModule),
    resourceCenterModule: ResourceCenterModule.ExtractVuexModule(ResourceCenterModule),
  },
});

export const vxManager = {
  appStateModule: AppStateModule.CreateProxy(store, AppStateModule),
  discountOverridesModule: DiscountOverridesModule.CreateProxy(nonPersistedStore, DiscountOverridesModule),
  accessoriesModule: AccessoriesModule.CreateProxy(nonPersistedStore, AccessoriesModule),
  lockersModule: LockersModule.CreateProxy(store, LockersModule),
  orderModule: OrdersModule.CreateProxy(nonPersistedStore, OrdersModule),
  organizationsModule: OrganizationsModule.CreateProxy(nonPersistedStore, OrganizationsModule),
  permissionsModule: PermissionsModule.CreateProxy(store, PermissionsModule),
  persistentDataModule: PersistentDataModule.CreateProxy(store, PersistentDataModule),
  projectPartsModule: ProjectPartsModule.CreateProxy(store, ProjectPartsModule),
  projectSettingsModule: ProjectSettingsModule.CreateProxy(store, ProjectSettingsModule),
  projectsModule: ProjectsModule.CreateProxy(nonPersistedStore, ProjectsModule),
  provincesModule: ProvincesModule.CreateProxy(store, ProvincesModule),
  quotesModule: QuotesModule.CreateProxy(nonPersistedStore, QuotesModule),
  roomDesignerModule: RoomDesignerModule.CreateProxy(store, RoomDesignerModule),
  roomScreenModule: RoomScreenModule.CreateProxy(store, RoomScreenModule),
  roomsModule: RoomsModule.CreateProxy(store, RoomsModule),
  resourceCenterModule: ResourceCenterModule.CreateProxy(nonPersistedStore, ResourceCenterModule),
  updatesModule: UpdatesModule.CreateProxy(store, UpdatesModule),
  userModule: UserModule.CreateProxy(store, UserModule),
  userSettingsModule: UserSettingsModule.CreateProxy(store, UserSettingsModule),
  expediteRequestsModule: ExpediteRequestsModule.CreateProxy(store, ExpediteRequestsModule),
};
