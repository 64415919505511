import HttpService from '@/endpoints/HttpService';
import HttpResponse from '@/interfaces/HttpResponse';
import ShippingAddress from '@/models/addresses/shippingAddress';
import DryersShippingRatesResponse from '@/models/accessories/dryersShippingRates';
import LeadTimeResponse from '@/models/leadTime/leadTimeResponse';
import CreationViewModel from '@/models/orders/CreationViewModel';
import { LargeShipmentRequest } from '@/models/orders/CreationViewModel';
import { OrderFreightProviderRequest } from '@/models/orders/OrderAPIInterfaces';
import Project from '@/models/project/project';
import QuoteCreationRequest, {
  QuotesListResponse,
  QuoteAddressChangeRequest,
  QuotePatchRequest,
  QuotePutShippingMethodOptionsRequest,
  QuoteResponse,
} from '@/models/quotes/QuoteAPIInterfaces';
import Quote, { CompleteLargeShipmentRequest } from '@/models/quotes/quote';
import FreightCarrier from '@/models/shipping/freightCarrier';
import FreightProvider from '@/models/shipping/freightProvider';
import ShippingMethod from '@/models/shipping/shippingMethod';
import ZipcodeQueryResponse from '@/models/shipping/zipCode';
import ProjectParts from '@/models/part/projectParts';
import LeadTime from '@/models/leadTime/leadTime';

const quotesURL = `${process.env.VUE_APP_API_PREFIX}quotes`;
const accessoriesURL = `${process.env.VUE_APP_API_PREFIX}accessories`;

export interface QuoteLeadTimeRequest {
  selectedLeadTimeOption: LeadTime | undefined;
  selectedDeliveryDate: Date | undefined;
  quoteId: string;
  projectId: string;
}

export interface QuoteShippingInfoResponse {
  shippingMethods: ShippingMethod[];
  freightCarriers: FreightCarrier[];
  quote: Quote;
}

export default class QuotesService {
  public static async getQuotesForProject(request: string): Promise<QuotesListResponse> {
    const response = await HttpService.get(`${quotesURL}?${request}`);
    return response.data;
  }

  public static async fetchDryerProjectParts(quoteId: string): Promise<ProjectParts[]> {
    const response = await HttpService.getWithLoading(`${quotesURL}/${quoteId}/dryerProjectParts`);
    return response.data.entities;
  }

  public static async validateQuotesForProject(request: string) {
    await HttpService.post(`${quotesURL}/${request}/validation`);
  }

  public static async getLargeShipmentsForQuotes(quoteId: string): Promise<LargeShipmentRequest[]> {
    const response = await HttpService.get(`${quotesURL}/largeShipments?quoteId=${quoteId}`);
    return response.data;
  }

  public static async createQuote(request: QuoteCreationRequest): Promise<HttpResponse<QuoteResponse>> {
    const response = await HttpService.post(quotesURL, request);
    return response;
  }

  public static async deleteQuote(quoteId: string): Promise<void> {
    await HttpService.delete(`${quotesURL}/${quoteId}`);
  }

  public static async patchQuote<T extends keyof CreationViewModel>(
    requests: Array<QuotePatchRequest<T>>
  ): Promise<HttpResponse<QuoteResponse>> {
    const response = await HttpService.patch(`${quotesURL}/${requests[0].quoteId}`, requests);
    return response;
  }

  public static async updateLeadTimeAndGetShippingInfo(
    request: QuoteLeadTimeRequest
  ): Promise<QuoteShippingInfoResponse> {
    const response = await HttpService.put(`${quotesURL}/${request.quoteId}/leadTime`, request);
    return response.data;
  }

  public static async putShippingMethodOptions(request: QuotePutShippingMethodOptionsRequest): Promise<QuoteResponse> {
    const { projectId, quoteId, ...rest } = request;
    const response = await HttpService.put(`${quotesURL}/${request.quoteId}/shippingMethodOptions`, rest);
    return response.data;
  }

  public static async changeQuoteAddress(request: QuoteAddressChangeRequest): Promise<QuoteResponse> {
    const response = await HttpService.post(`${quotesURL}/${request.quoteId}/shippingAddress`, request.value);
    return response.data;
  }

  public static async getPickUpAddressForQuote(quotedId: string): Promise<ShippingAddress> {
    const response = await HttpService.get(`${quotesURL}/${quotedId}/pickupAddress`);
    return response.data.entity;
  }

  public static async getLeadTimesForQuote(quotedId: string): Promise<LeadTimeResponse> {
    const response = await HttpService.get(`${quotesURL}/${quotedId}/leadTimes`);
    return response.data.entity;
  }

  public static async getQuoteFreightCarriers(quoteId: string): Promise<FreightCarrier[]> {
    const response = await HttpService.get(`${process.env.VUE_APP_API_PREFIX}freight/freightCarriers/quote/${quoteId}`);
    return response.data.entities;
  }

  public static async getOrderFreightCarriers(orderId: string): Promise<FreightCarrier[]> {
    const response = await HttpService.get(`${process.env.VUE_APP_API_PREFIX}freight/freightCarriers/order/${orderId}`);
    return response.data.entities;
  }

  public static async getFreightProvidersForQuote(
    orderFreightProviderRequest: OrderFreightProviderRequest
  ): Promise<FreightProvider[]> {
    const response = await HttpService.post(`${quotesURL}/${orderFreightProviderRequest.orderId}/freightProviders`);
    return response.data.entities;
  }
  public static async updateFreightProvidersForQuote(
    orderFreightProviderRequest: OrderFreightProviderRequest
  ): Promise<FreightProvider[]> {
    const response = await HttpService.post(
      `${quotesURL}/${orderFreightProviderRequest.orderId}/freightProviders/updateMarkupCharges`,
      orderFreightProviderRequest.freightProvidersToUpdate
    );
    return response.data.entities;
  }

  public static async getZipCodeAddress(zipCode: string): Promise<ZipcodeQueryResponse> {
    const response = await HttpService.put(`${quotesURL}/byZipCode?zipCode=${zipCode}`);
    return response.data.entity;
  }

  public static async getDryerShippingRatesForQuote(quoteId: string): Promise<DryersShippingRatesResponse> {
    const response = await HttpService.get(`${accessoriesURL}/shippingRates/quote/${quoteId}`);
    return response.data.entity;
  }

  public static async duplicateQuoteAsNewProject(quoteId: string): Promise<Project> {
    const response = await HttpService.postWithLoading(`${quotesURL}/${quoteId}/newProject`);
    return response.data.entity;
  }

  public static async completeLargeShipmentRequest(request: CompleteLargeShipmentRequest) {
    return await HttpService.putWithLoading(`${quotesURL}/${request.entityId}/largeShipment`, request);
  }
}
