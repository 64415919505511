import PermissionTypes from '@/enums/permissionTypes';
import PermissionsHelper from '@/helpers/permissionsHelper';
import i18n from '@/i18n';
import Organization from '@/models/permission/organization';
import { CreationTypes, ProjectProductTypes } from '@/models/project/projectApiInterfaces';
import { FilteredSelectorItem } from '@/shared/viewParts/filteredSelector/filteredSelector';
import { vxManager } from '@/store/store';

export const ProjectProductAllTypes: FilteredSelectorItem[] = [
  {
    label: i18n.global.t('projects.partitions').toString(),
    value: { id: ProjectProductTypes.Partition },
    key: CreationTypes.Partitions,
  },
  {
    label: i18n.global.t('projects.lockers').toString(),
    value: { id: ProjectProductTypes.Lockers },
    key: CreationTypes.Lockers,
  },
  {
    label: i18n.global.t('projects.washroomAccessories').toString(),
    value: { id: ProjectProductTypes.WashroomAccessories },
    key: CreationTypes.WashroomAccessories,
  },
];

export function GetAllowedProductTypes(organisation?: Organization | undefined): FilteredSelectorItem[] {
  const productTypes = [] as FilteredSelectorItem[];
  if (organisation) {
    if (PermissionsHelper.analyzePermissions(PermissionTypes.CanCreatePartitions, organisation.id)) {
      productTypes.push(ProjectProductAllTypes[0]);
    }
    if (PermissionsHelper.analyzePermissions(PermissionTypes.CanCreateLockers, organisation.id)) {
      productTypes.push(ProjectProductAllTypes[1]);
    }
    if (PermissionsHelper.analyzePermissions(PermissionTypes.CanCreateWashroomAccessories, organisation.id)) {
      productTypes.push(ProjectProductAllTypes[2]);
    }
    return productTypes;
  }

  if (vxManager.userModule.userCanCreatePartitions) {
    productTypes.push(ProjectProductAllTypes[0]);
  }

  if (vxManager.userModule.userCanCreateLockers) {
    productTypes.push(ProjectProductAllTypes[1]);
  }

  if (vxManager.userModule.userCanCreateDryers) {
    productTypes.push(ProjectProductAllTypes[2]);
  }

  return productTypes;
}
