import HttpService from '@/endpoints/HttpService';
import EventBus from '@/eventBus';
import Events from '@/events';
import Toast from '@/helpers/toast';
import i18n from '@/i18n';
import HttpResponse, { EntityObject } from '@/interfaces/HttpResponse';
import ShippingAddress from '@/models/addresses/shippingAddress';
import DryersShippingRatesResponse from '@/models/accessories/dryersShippingRates';
import LeadTimeResponse from '@/models/leadTime/leadTimeResponse';
import { LargeShipmentRequest } from '@/models/orders/CreationViewModel';
import Order from '@/models/orders/Order';
import OrderCreationRequest, {
  OrdersListResponse,
  OrderAddressChangeRequest,
  OrderFreightProviderRequest,
  OrderPatchOperation,
  OrderPutShippingMethodOptionsRequest,
  OrderReport,
  OrderResponse,
  ProjectOrdersListResponse,
} from '@/models/orders/OrderAPIInterfaces';
import { OrderValidation } from '@/models/orders/OrderListItemReply';
import { CompleteLargeShipmentRequest } from '@/models/quotes/quote';
import OrderBom from '@/models/shipping/OrderBom';
import FreightProvider from '@/models/shipping/freightProvider';
import ShippingMethod from '@/models/shipping/shippingMethod';
import { OrderItemsRequest } from '@/store/ordersModule/ordersModule';
import { ProjectDetailsTabsName } from '@/views/projects/projectDetails/projectDetailsTabs/projectDetailsTabs';
import ProjectParts from '@/models/part/projectParts';
import ShippingOptions from '@/models/shipping/shippingOptions';
import LeadTime from '@/models/leadTime/leadTime';
import FreightCarrier from '@/models/shipping/freightCarrier';

export interface OrderLeadTimeRequest {
  selectedLeadTimeOption: LeadTime | undefined;
  selectedDeliveryDate: Date | undefined;
  orderId: string;
  projectId: string;
}

export interface OrderShippingInfoResponse {
  shippingMethods: ShippingMethod[];
  freightCarriers: FreightCarrier[];
  order: Order;
}

const ordersURL = `${process.env.VUE_APP_API_PREFIX}orders`;
const accessoriesURL = `${process.env.VUE_APP_API_PREFIX}accessories`;

export default class OrdersService {
  public static async getOrdersForProject(request: string): Promise<ProjectOrdersListResponse> {
    const response = await HttpService.getWithLoading(`${ordersURL}?${request}`);
    return response.data;
  }

  public static async fetchDryerProjectParts(orderId: string): Promise<ProjectParts[]> {
    const response = await HttpService.getWithLoading(`${ordersURL}/${orderId}/dryerProjectParts`);
    return response.data.entities;
  }

  public static async getOrderCountForProject(request: string): Promise<number> {
    const response = await HttpService.getWithLoading(`${ordersURL}/count?${request}`);
    return response.data;
  }

  public static async validateOrdersForProject(request: string) {
    await HttpService.post(`${ordersURL}/${request}/validation`);
  }

  public static async getOrdersList(params: OrderItemsRequest): Promise<OrdersListResponse> {
    const response = await HttpService.get(ordersURL, { params });
    return response.data;
  }

  public static async getOrdersCount(params: OrderItemsRequest = {} as OrderItemsRequest): Promise<number> {
    const response = Object.keys(params).length
      ? await HttpService.get(`${ordersURL}/count`, { params })
      : await HttpService.get(`${ordersURL}/count`);
    return response.data;
  }

  public static async getOrderById(orderId: string): Promise<Order> {
    const response = await HttpService.post(`${ordersURL}/${orderId}/orderReady`);
    return response.data.entity;
  }

  public static async validateOrder(orderId: string): Promise<OrderValidation> {
    const response = await HttpService.get(`${ordersURL}/${orderId}/validation`);
    return response.data;
  }

  public static async deleteOrder(orderId: string): Promise<void> {
    await HttpService.delete(`${ordersURL}/${orderId}`);
  }

  public static async getBomForOrder(orderId: string): Promise<OrderBom> {
    const response = await HttpService.post(`${ordersURL}/${orderId}/bom`);
    return response.data.entity;
  }

  public static async createOrder(request: OrderCreationRequest): Promise<HttpResponse<OrderResponse>> {
    const response = await HttpService.post(ordersURL, request);
    return response;
  }

  public static async patchOrder(operation: OrderPatchOperation): Promise<HttpResponse<OrderResponse>> {
    const response = await HttpService.patch(`${ordersURL}/${operation.orderId}`, operation.operations);
    return response;
  }

  public static async updateLeadTimeAndGetShippingInfo(
    request: OrderLeadTimeRequest
  ): Promise<OrderShippingInfoResponse> {
    const response = await HttpService.put(`${ordersURL}/${request.orderId}/leadTime`, request);
    return response.data;
  }

  public static async putOrderShippingMethodOptions(
    request: OrderPutShippingMethodOptionsRequest
  ): Promise<OrderResponse> {
    const { projectId, orderId, ...rest } = request;
    const response = await HttpService.put(`${ordersURL}/${request.orderId}/shippingMethodOptions`, rest);
    return response.data;
  }

  public static async changeOrderAddress(request: OrderAddressChangeRequest): Promise<EntityObject<ShippingOptions>> {
    const response = await HttpService.post(`${ordersURL}/${request.orderId}/shippingAddress`, request.value);
    return response.data;
  }

  public static async getPickUpAddressForOrder(orderedId: string): Promise<ShippingAddress> {
    const response = await HttpService.get(`${ordersURL}/${orderedId}/pickupAddress`);
    return response.data.entity;
  }

  public static async getLeadTimesForOrder(orderedId: string): Promise<LeadTimeResponse> {
    const response = await HttpService.get(`${ordersURL}/${orderedId}/leadTimes`);
    return response.data.entity;
  }

  public static async getFreightProvidersForOrder(
    orderFreightProviderRequest: OrderFreightProviderRequest
  ): Promise<FreightProvider[]> {
    const response = await HttpService.post(`${ordersURL}/${orderFreightProviderRequest.orderId}/freightProviders`);
    return response.data.entities;
  }

  public static async getDryerShippinRatesForOrder(orderId: string): Promise<DryersShippingRatesResponse> {
    const response = await HttpService.get(`${accessoriesURL}/shippingRates/order/${orderId}`);
    return response.data.entity;
  }

  public static async updateFreightProvidersForOrder(
    orderFreightProviderRequest: OrderFreightProviderRequest
  ): Promise<FreightProvider[]> {
    const response = await HttpService.post(
      `${ordersURL}/${orderFreightProviderRequest.orderId}/freightProviders/updateMarkupCharges`,
      orderFreightProviderRequest.freightProvidersToUpdate
    );
    return response.data.entities;
  }

  public static async confirmOrder(orderId: string): Promise<string> {
    const response = await HttpService.post(`${ordersURL}/${orderId}`);
    return response.data;
  }

  public static async resendOrder(orderId: string): Promise<void> {
    await HttpService.post(`${ordersURL}/${orderId}/JDE`);
  }

  public static async pollForOrderReport(report: OrderReport): Promise<void> {
    try {
      const response = await HttpService.post(`${ordersURL}/report`, report);
      if (response.status === 200 || report.retries++ >= 10) {
        Toast.success({
          body: i18n.global.t('orders.jdeOrderConfirm', [response.data.orderNumber]),
          link: `/projects/${report.projectId}/tab/${ProjectDetailsTabsName.Orders}`,
          linkText: i18n.global.t('orders.goToOrderButton').toString(),
        });
        EventBus.$emit(Events.updateOrders);
      } else {
        setTimeout(() => {
          this.pollForOrderReport(report);
        }, 10000 * report.retries);
      }
    } catch (error) {
      setTimeout(() => {
        this.pollForOrderReport(report);
      }, 10000 * report.retries);
    }
  }

  public static async completeLargeShipmentRequest(request: CompleteLargeShipmentRequest) {
    return await HttpService.put(`${ordersURL}/${request.entityId}/largeShipment`, request);
  }

  public static async getLargeShipmentsForOrders(orderId: string): Promise<LargeShipmentRequest[]> {
    const response = await HttpService.get(`${ordersURL}/largeShipments?orderId=${orderId}`);
    return response.data;
  }
}
