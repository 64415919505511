import { action, mutation, Module, VuexModule } from 'vuex-class-component';
import AccessoriesService from '@/endpoints/accessoriesService';
import CoverMaterials from '@/models/accessories/coverMaterials';
import DryerModels from '@/models/accessories/dryerModels';
import AccessoriesData from '@/models/accessories/accessoriesData';
import DryersMountings from '@/models/accessories/dryerMountings';
import Operations from '@/models/accessories/operations';
import Voltages from '@/models/accessories/voltages';
import SoapDispensersMountings from '@/models/accessories/soapDispenserMountings';
import SoapDispenserModels from '@/models/accessories/soapDispenserModels';

@Module()
export class AccessoriesModule extends VuexModule {
  get allDryerModels(): DryerModels[] {
    return this.accessoriesStaticData?.dryersStaticReply.dryerModels ?? [];
  }
  get allSoapDispenserModels(): SoapDispenserModels[] {
    return this.accessoriesStaticData?.soapDispensersStaticReply.soapDispenserModels ?? [];
  }
  get allCoverMaterials(): CoverMaterials[] {
    return this.accessoriesStaticData?.dryersStaticReply.coverMaterials ?? [];
  }
  get allVoltages(): Voltages[] {
    return this.accessoriesStaticData?.dryersStaticReply.voltages ?? [];
  }
  get allDryerMountings(): DryersMountings[] {
    return this.accessoriesStaticData?.dryersStaticReply.dryerMountings ?? [];
  }
  get allSoapDispensersMountings(): SoapDispensersMountings[] {
    return this.accessoriesStaticData?.soapDispensersStaticReply.soapDispensersMountings ?? [];
  }
  get allOperations(): Operations[] {
    return this.accessoriesStaticData?.dryersStaticReply.operations ?? [];
  }

  private accessoriesStaticData: AccessoriesData;

  @action({ mode: 'mutate' })
  public async fetchStaticAccessoriesData(projectId: string) {
    const response = await AccessoriesService.getStaticAccessoriesData(projectId);
    this.setStaticAccessoriesData(response);
  }

  @mutation private setStaticAccessoriesData(data: AccessoriesData) {
    this.accessoriesStaticData = data;
  }
}
